import Image from 'next/image'
import Link from 'next/link'

export default function Custom404() {
	return (
		<section className="section h-full relative">
			{/* Bg image */}
			<Image
				src="https://cdn.raster.app/birchbox/2V5tEUcrSp?ixlib=js-3.6.0&height=3648&width=1500&compress=false&hue=33&sepia=22&s=269ef8a31b52088e65080ad2e2169305"
				alt="makeup scattered across a surface"
				layout="fill"
				objectFit="cover"
				objectPosition="top"
				priority
			/>

			<div className="container relative z-10 flex justify-start">
				<div className="p-10 bg-white lg:px-20 lg:py-16">
					<p className="max-lg:text-sm font-medium text-gray-500">Lost your way?</p>

					<h1 className="mt-2 font-serif text-2xl lg:text-3xl">We can’t find that page</h1>

					<Link legacyBehavior href="/">
						<a className="mt-8 lg:mt-10 button button-primary">Keep Shopping</a>
					</Link>
				</div>
			</div>
		</section>
	)
}
